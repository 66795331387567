import { onMounted, reactive, ref, watch } from "vue";
import Handsontable from "handsontable";

import { showError} from "@/libs/notification";
import { orderService } from "@/services/order";
import { Order, OrderPayload, OrderSearchForm, OrderStatus, TableColumn } from "@/configs/orders";
import { helper as $h } from "@/utils/helper";
import { columnConfigs, columnWidths, initialStatus } from "./columns";
import { Pagination } from "@/configs/common";
import { useRouter } from "vue-router";

export const useOrder: any = () => {
  const orderStatus = ref<OrderStatus[]>(initialStatus);
  const selectedOrder = ref<Order>({});
  const loading = ref(false);
  const hot = ref();
  const router = useRouter();
  const tableRef = ref<HTMLElement>();
  const formData = reactive(<OrderSearchForm>{
    dateRange: `12/08/2019 - 12/08/2019`,
  });
  const pagination = ref<Pagination>({
    page: 1,
    pageSize: 20,
  });
  const orders = ref<any[]>([]);
  const columns = ref<TableColumn[]>(columnConfigs);
  const sorter = reactive<{ sortField?: string; sortOrder?: string }>({});
  const sortFields: { [key: string]: string } = {
    "4": "excel_index",
    "5": "print_number",
    "6": "custom_code",
  };

  const setSorter = (config: { column: number; sortOrder: string }) => {
    sorter.sortField = sortFields[config.column.toString()];
    sorter.sortOrder = config.sortOrder;
    getOrders(config);
  };

  // Get Orders
  const preparePayload = (): OrderPayload => {
    const [startDate, endDate] = $h.dateRangeToDate(formData.dateRange);
    return {
      query: formData.query || undefined,
      page: pagination.value.page || 1,
      pageSize: pagination.value.pageSize || 20,
      sortField: sorter.sortField || undefined,
      sortOrder: sorter.sortOrder || undefined,
      status: formData.status,
      orderBy: "excel_index",
      startDate,
      endDate,
    };
  };

  const getOrders = async (config = {}) => {
    loading.value = true;
    hot.value.loadData([]);
    try {
      const result = (await orderService.getOrders(preparePayload())).result;
      orders.value = result.orders;
      pagination.value.total = result.total;
      hot.value.loadData(orders.value);
      const columnSortPlugin = hot.value.getPlugin("columnSorting");
      columnSortPlugin.setSortConfig(config);
      hot.value.render();
    } catch (error) {
      showError(error.response ? error.response.data.error : "Co loi xay ra");
    } finally {
      loading.value = false;
    }
  };

  const search = () => {
    getOrders();
  };

  // Pagination
  const handlePaginationChange = (newVal: Pagination) => {
    pagination.value = { ...pagination.value, ...newVal };
    getOrders();
  };

  // Handson Table
  const isChecked = () => {
    const find = orders.value.find((item) => !item.select);
    return !find;
  };

  const colHeaders = (col: number) => {
    let txt;

    switch (col) {
      case 0:
        txt = "<input type='checkbox' class='checker' ";
        txt += isChecked() ? 'checked="checked"' : "";
        txt += ">";

        return txt;

      default:
        return columns.value[col].header || "";
    }
  };

  const initHandson = () => {
    if (tableRef.value) {
      hot.value = new Handsontable(tableRef.value, {
        rowHeaders: true,
        colHeaders: colHeaders,
        columns: columns.value,
        afterOnCellMouseDown: function (e, coords) {
          if (coords.col === 2) {
            const billCode = hot.value.getDataAtRow(coords.row)[3];
            const find = orders.value.find((item) => item.billCode === billCode);
            if (find) {
              selectedOrder.value = find;
              cash("#order-edit-form").modal("show");
            }
          }
          if (coords.col === 1) {
            const billCode = hot.value.getDataAtRow(coords.row)[3];
            router.push({
              name: "order-tracking",
              query: {
                billCode: billCode,
              },
            });
          }
        },
        beforeColumnSort: function (prev, targetConfigs) {
          const config = targetConfigs[0];
          if ([4, 5, 6].includes(config.column)) {
            setSorter(config);
          }
          return false;
        },
        columnSorting: true,
        colWidths: columnWidths,
        rowHeights: 35,
        stretchH: "all",
        className: "htMiddle handsongray",
        fixedColumnsLeft: 4,
        licenseKey: "non-commercial-and-evaluation",
        width: "100%",
        height: "100%",
      });
      Handsontable.dom.addEvent(tableRef.value, "mousedown", function (event: any) {
        if (event.target.nodeName === "INPUT" && event.target.className === "checker") {
          event.stopPropagation();
        }
      });

      Handsontable.dom.addEvent(tableRef.value, "mouseup", function (event: any) {
        if (event.target.nodeName === "INPUT" && event.target.className === "checker") {
          const check = isChecked();
          orders.value.forEach((e) => (e.select = !check));
          hot.value.render();
        }
      });
    }
  };

  watch(
    () => formData.query,
    (val: any) => {
      formData.query = val.replace(" ", ",").trim();
    }
  );

  onMounted(() => {
    initHandson();
    getOrders();
  });

  return {
    tableRef,
    pagination,
    search,
    orderStatus,
    handlePaginationChange,
    formData,
    loading,
    columns,
    orders,
    getOrders,
    selectedOrder,
  };
};
