
import { defineAsyncComponent, defineComponent } from "vue";
import "handsontable/dist/handsontable.full.css";

import { useOrder } from "./useOrder";

const Pagination = defineAsyncComponent(
  () => import("@/components/pagination/Main.vue")
);
const OrderEditModal = defineAsyncComponent(
  () => import("@/components/order-edit-modal/Main.vue")
);

export default defineComponent({
  components: {
    Pagination,
    OrderEditModal,
  },
  setup() {
    const {
      tableRef,
      formData,
      orders,
      handlePaginationChange,
      search,
      pagination,
      columns,
      loading,
      selectedOrder,
    } = useOrder();
    return {
      tableRef,
      formData,
      handlePaginationChange,
      columns,
      pagination,
      orders,
      loading,
      search,
      selectedOrder,
    };
  },
  methods: {
    showExportModal() {
      cash("#export-modal").modal("show");
    },
  },
});
