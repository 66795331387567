<template>
  <div class="mt-2">
    <!-- BEGIN: Filter -->
    <div class="intro-y box px-5 pt-2">
      <div
        class="grid grid-cols-12 border-b border-gray-200 dark:border-dark-5 pb-2 -mx-5 gap-2 pl-2"
      >
        <div class="col-span-5">
          <input
            v-model="formData.query"
            type="text"
            class="input w-full border"
            placeholder="Nhập số theo dõi, số điện thoại hoặc mã khách hàng"
          />
        </div>
        <div class="col-span-2">
          <button
            type="button"
            class="button bg-theme-1 flex justify-center text-white"
            @click="search"
          >
            <LoadingIcon color="white" class="w-4 h-4 mr-1" v-if="loading" />
            <SearchIcon class="w-4 h-4 mr-1" v-else /> Tìm kiếm
          </button>
        </div>
      </div>
    </div>
    <!-- END: Filter -->
    <!-- BEGIN: Function Button -->
    <div class="box mt-4 handson z-0">
      <div
        id="handson"
        ref="tableRef"
        class="hot handsontable htRowHeaders htColumnHeaders"
        style="height: 100%; overflow: hidden; width: 100%"
        data-originalstyle="height: 100%; overflow: hidden; width: 100%;"
      ></div>
    </div>
    <!-- End: Handson Table -->
    <Pagination
      :pagination="pagination"
      @handlePaginationChange="handlePaginationChange"
    />
  </div>
  <OrderEditModal :order="selectedOrder" :readonly="true" />
</template>

<script lang="ts">
import { defineAsyncComponent, defineComponent } from "vue";
import "handsontable/dist/handsontable.full.css";

import { useOrder } from "./useOrder";

const Pagination = defineAsyncComponent(
  () => import("@/components/pagination/Main.vue")
);
const OrderEditModal = defineAsyncComponent(
  () => import("@/components/order-edit-modal/Main.vue")
);

export default defineComponent({
  components: {
    Pagination,
    OrderEditModal,
  },
  setup() {
    const {
      tableRef,
      formData,
      orders,
      handlePaginationChange,
      search,
      pagination,
      columns,
      loading,
      selectedOrder,
    } = useOrder();
    return {
      tableRef,
      formData,
      handlePaginationChange,
      columns,
      pagination,
      orders,
      loading,
      search,
      selectedOrder,
    };
  },
  methods: {
    showExportModal() {
      cash("#export-modal").modal("show");
    },
  },
});
</script>
